import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import {
  BatchListResponse, BatchDetailPost, Batch_DetailResponse, MasterFormulationRecord, Batch_comment, Modification_CC_info, Modification_Compound_info
} from '../../../module/production/model/manage-batch-record.interface';
import { ToastrService } from 'ngx-toastr';
import { ToastBaseModel } from '../../model/toast-data-model';
import { DataService } from '../../service/data-service';
import { Configuration } from '../../../app.configuration'
import { ConfirmationService } from 'primeng/api';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { EALREADY } from 'constants';
import { StorageConfiguration } from '@app/shared/common/storage-setting/storage-configuration';

@Component({
  selector: 'app-packing',
  templateUrl: './packing.component.html',
  styleUrls: ['./packing.component.sass'],
  providers: [ConfirmationService]
})
export class PackingComponent implements OnInit {


  keyCode: number;
  LowerCaseForm: FormGroup;
  responseBody: any;
  Unitindex: number;
  Case_detailsLength: any;
  Is_completed: boolean;
  generateCaseShow: boolean;
  totalLength = 0;
  allowSave: boolean;
  BatchOptionData2: any;
  currentCasNo: any;
  // LowlevelcaseDetailModel: { Name: string; Barcode: string; };
  // CaseDetailModel: { Case_no: string; Lowlevelcase_details: any[]; };
  BatchCaseModel: { Is_completed: boolean; Case_details: any[]; };
  CaseDetailModel: { Case_no: string, Is_filled: boolean, Used_quantity: number, Total_quantity: number, Available_quantity: number, Units: any[] };



  @Input() BatchDetailForm: FormGroup;
  @Input() batchUnits: any;
  // @Input() Actual_yield: any;
  // @Input() Quantity_per_case: any;
  @Input() ShowPackage: boolean;
  @Output() BatchPackageEmitter = new EventEmitter();
  @ViewChild('focusInputBarcode', { static: false }) focusInputBarcode: ElementRef;
  @ViewChild('focusInputBarcodeFirst', { static: false }) focusInputBarcodeFirst: ElementRef;

  caseNumberData: any;
  display_PackageAddPopup: Boolean;
  display_SamplePackageAddPopup: Boolean;
  rootUrl = 'BatchRecord';
  Pack = { id: '', Barcode: '', Is_used: false, Status: '' };
  TabIndex = 0;
  QPs_disable: any;
  Ay_disable: any;

  display_generateCasePopup: boolean;
  maskDisplay: boolean;
  caseType: string;
  addType: string;
  Actual_yield: any;
  Quantity_per_case: any;
  UserCodeForm: FormGroup;
  display_UserVerifyPopup: boolean;
  verifyType = '';
  selectedCaseUnits = [];
  chkDisable: boolean = false;
  selectedLabels: any[] = [];
  selectedLot: string = '';
  unfilledCaseLength: number;
  Is_custom_label: boolean = false;	
  Tenant_id: any;					  

  constructor(
    private fb: FormBuilder,
    private spinnerService: Ng4LoadingSpinnerService,
    private dataService: DataService,
    private configuration: Configuration,
    private toastMessage: ToastBaseModel,
    private toastr: ToastrService,
    private confirmationService: ConfirmationService,
    private storageConfiguration: StorageConfiguration,
  ) {

    this.LowerCaseForm = this.fb.group({
      id: '',
      Barcode: ''
    });
    this.UserCodeForm = this.fb.group({
      UserCode: [''],
      Type: ['Verified'],
      ModelType: ['5'],
    });

  }


  ngOnInit(): void {

    let BatchDetailFormValue = this.BatchDetailForm.getRawValue();
    this.Is_custom_label = BatchDetailFormValue.Is_custom_label;
    this.ShowPackage = false;
    if (this.batchUnits.Batch_status != BatchDetailFormValue.Status) {
      this.Actual_yield = this.batchUnits.Actual_yield = BatchDetailFormValue.Actual_yield;
      this.Quantity_per_case = this.batchUnits.Master_formulation_info.Quantity_per_case = BatchDetailFormValue.Master_formulation_record.Quantity_per_case;
      this.batchUnits.Batch_status = BatchDetailFormValue.Status;
    } else {
      this.Actual_yield = this.batchUnits.Actual_yield;
      this.Quantity_per_case = this.batchUnits.Master_formulation_info.Quantity_per_case;

    }

    this.Tenant_id = this.dataService.getTenentId(),
    // this.userInfo = JSON.parse(this.storageConfiguration.sessionGetItem(this.storageConfiguration.user_Info));

    this.caselengthFinder(0);
    this.ShowPackage = true;
    this.caseLengthCheck();
  }
  caselengthFinder(count) {

    const Batch_case = this.batchUnits;
    this.Case_detailsLength = Batch_case.Scanned_labels.Case_details.length;
    this.TabIndex = Batch_case.Tab_index + count;
  }

  generateCase() {
    
    // let unitCount = this.Actual_yield / this.BatchDetailForm.get('Master_formulation_record.Quantity_dispensed_per_container.Units').value;
    // unitCount = + Math.round(unitCount);
    const unitCount = this.BatchDetailForm.get('Units_container').value;

    if (this.Quantity_per_case > unitCount) {
      this.toastr.warning('Quantity Per Case should be less than ' + unitCount);
    } else {

      this.BatchDetailForm.patchValue({
        Actual_yield: this.Actual_yield,
        Master_formulation_record: {
          Quantity_per_case: this.Quantity_per_case
        }
      });
      this.batchUnits.Actual_yield = this.Actual_yield;
      this.batchUnits.Master_formulation_info.Quantity_per_case = this.Quantity_per_case;


      const Quantity_per_case = this.Quantity_per_case;
      const Actual_yield = this.Actual_yield;
      if (this.caseType === 'case' && Quantity_per_case != 0 && Quantity_per_case != '' && Quantity_per_case != null && Quantity_per_case != undefined && Actual_yield != undefined && Actual_yield != null && Actual_yield != 0 && Actual_yield != '') {
        this.spinnerService.show();
        this.dataService.add<any>(this.configuration.batchRecord + '/GetBatchCaseList?casePerContainer=' + Quantity_per_case + '&actualYield=' + unitCount.toString(), '').subscribe(
          (data: any) => (this.caseNumberData = data),
          error => () => {

            this.spinnerService.hide();
          },
          () => {


            this.caseBinder(this.caseNumberData.result);
            this.TabIndex = 0;
            this.display_generateCasePopup = false;
            this.BatchUpdate();
            this.caseLengthCheck();
          }
        );
      } else if (this.caseType !== 'case' && Actual_yield !== undefined && Actual_yield != null && Actual_yield != 0 && Actual_yield !== '') {
        const array = ['Individual Units'];
        this.caseBinder(array);
        this.TabIndex = 0;
        this.display_generateCasePopup = false;
        this.BatchUpdate();
        this.caseLengthCheck();
      } else {
        this.toastr.warning('Please Enter Required Fields');
      }

    }
  }

  caseBinder(data) {
    const Case_details = [];
    data.forEach(element => {

      const CaseDetailModel = { Case_no: '', Is_filled: false, Used_quantity: 0, Total_quantity: 0, Available_quantity: 0, Units: [] };
      // let CaseDetailModel = { Case_no: '', Lowlevelcase_details: [] };       

      CaseDetailModel.Case_no = element;
      Case_details.push(CaseDetailModel);
    });

    // this.BatchDetailForm.patchValue({
    //   Batch_case: BatchCaseModel
    // });

    this.batchUnits.Scanned_labels.Case_details = Case_details;
    this.spinnerService.hide();
    this.caselengthFinder(1);
  }

  addPackingDialog() {
        // var data = this.BatchDetailForm.get('Batch_case.Case_details').value;
        var data = this.batchUnits.Scanned_labels.Case_details;
        const item = this.currentCasNo = data[this.TabIndex].Case_no;
        var QtyPerCase = this.Quantity_per_case;
        const scannedData = this.batchUnits.Scanned_labels.Available_quantity;
        // let unitCount = this.batchUnits.Actual_yield /
        //   this.BatchDetailForm.get('Master_formulation_record.Quantity_dispensed_per_container.Units').value;
        // unitCount = Math.round(unitCount);
        const unitCount = this.BatchDetailForm.get('Units_container').value;
    
        if (item === 'Individual Units') {
          if (this.TabIndex > 0) {
            QtyPerCase = unitCount % QtyPerCase;
          } else {
            QtyPerCase = unitCount;
          }
        }
    
        data.forEach(element => {
          if (element.Units == null) {
            element.Units = [];
          }
          if (element.Case_no == item) {
            this.Unitindex = element.Units.length;
            if ((element.Units.length < QtyPerCase) || (scannedData == '')) {
              this.allowSave = true;
            } else {
              this.toastr.warning('Case Count Exceed Please Fill in Next Case');
              this.allowSave = false;
            }
          }
    
        });
        if (this.allowSave) {
          this.addPackage();
        }
      }
    
  addPackage() {
    let value = this.display_PackageAddPopup;
    this.display_PackageAddPopup = !value
    setTimeout(() => this.focusInputBarcode.nativeElement.focus(), 0);
    // this.LowerCaseForm.patchValue(
    //   {
    //     id: this.Unitindex.toString()
    //   }
    // )
  }
  hidePackage() {
    this.display_PackageAddPopup = false;
    this.display_generateCasePopup = false;
    this.display_SamplePackageAddPopup = false;
    this.display_UserVerifyPopup = false;
  }

  handleChange(e) {
  this.display_PackageAddPopup = false;
    localStorage.setItem('Tabindex', e.index);
    this.TabIndex = e.index;
    this.batchUnits.Tab_index = e.index;
    if (this.display_PackageAddPopup) {
      setTimeout(() => this.focusInputBarcode.nativeElement.focus(), 0);
    }
    this.selectedLabels = [];
    this.selectedCaseUnits = [];
    this.caseLengthCheck();
    this.selectedLot = '';
  }
  mainTabOnChange(e) {
    if (e.index == 1) { setTimeout(() => this.focusInputBarcodeFirst.nativeElement.focus(), 0); }
    if (e.index == 2) { setTimeout(() => this.focusInputBarcode.nativeElement.focus(), 0); }
    this.caseLengthCheck();

  }

  SavePackage(event) {
    if (this.barcodeVerifier()) {
      const data = this.batchUnits.Scanned_labels.Case_details;
      const filteredarray = data.filter(x => x.Is_filled == false);
      const targetTab = filteredarray[0];
      const tagetIndex = data.findIndex(x => x.Case_no === targetTab.Case_no);
      this.currentCasNo = targetTab.Case_no;
      this.TabIndex = this.batchUnits.Tab_index = tagetIndex;
      const a = this.LowerCaseForm.value;
      const lotNo = a.Barcode.slice(15, 21);  
      const packageNo = a.Barcode.slice(31);
      // const indexNo = data.findIndex(x => x.Index_no);
      a.Index_no = this.TabIndex;
      a.id = packageNo;
      const caseNo = this.currentCasNo;
      const UnitDetail = this.Pack = this.LowerCaseForm.value;
      // const data = this.batchUnits.Scanned_labels.Case_details;
      const sampleData = this.batchUnits.Sample_units;
      let QtyPerCase = parseInt(this.Quantity_per_case);
      let alreadyexist = false;
      let existsInSample = false;
      const samplefilteredData = sampleData.Units.filter(x => x.Barcode === a.Barcode);
      if (samplefilteredData.length > 0) {
        existsInSample = true;
      }
      data.forEach(element => {
        const filteredData = element.Units.filter(x => x.Barcode === a.Barcode);
        if (filteredData.length > 0) {
          alreadyexist = true;
        }
      });

      const sampleunits = this.batchUnits.Sample_units.Units.length;
      const destroyeddata = this.batchUnits.Destroyed_labels.Units.length;
      //const scannedData = this.batchUnits.Scanned_labels.Case_details[0].Case_no;
      const scannedData = this.batchUnits.Scanned_labels.Case_details[0].Case_no;
      // let unitCount = this.batchUnits.Actual_yield /
      //   this.BatchDetailForm.get('Master_formulation_record.Quantity_dispensed_per_container.Units').value;
      // unitCount = Math.round(unitCount);
      const unitCount = this.BatchDetailForm.get('Units_container').value;
      const unitCounts = this.BatchDetailForm.get('Label_details.Labels_generated').value;
      
      if (caseNo === 'Individual Units') {
        if (this.TabIndex > 0) {
          QtyPerCase = unitCount  % QtyPerCase;
        } else {
          QtyPerCase = unitCount;
        }
      }

      if (existsInSample) {
        this.toastr.warning('Label already exist');
        this.LowerCaseForm.reset();
      } else if (alreadyexist) {
        this.toastr.warning('Label already exist in sample');
        this.LowerCaseForm.reset();
      } else if (lotNo !== this.batchUnits.Lot_number) {
        this.toastr.warning(`Scanned Label is not associated with the Lot Number( ${this.batchUnits.Lot_number} )`);
        this.LowerCaseForm.reset();
      } else {

        let totalLength = 0;
        data.forEach(element => {
          if(scannedData !== 'Individual Units'){
           if (element.Case_no == caseNo) {
            if (element.Units.length < QtyPerCase) {
               if (element.Units.length + 1 === QtyPerCase) {
                 element.Is_filled = true;
               }
              this.allowSave = true;
              element.Units.push(UnitDetail);
              element.Units.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));

            } else {
              this.toastr.warning('Case Count Exceed Please Fill in Next Case');
              this.allowSave = false;
            }
          }
        }else{
          if (element.Case_no == caseNo) {
            if (element.Units.length < unitCounts && this.batchUnits.Available_labels.Units.length != 0 ) {
               if (element.Units.length + 1 === unitCounts) {
                 element.Is_filled = true;
               }
              this.allowSave = true;
              element.Units.push(UnitDetail);
              element.Units.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));

            } else {
              this.batchUnits.Scanned_labels.Is_completed = true;
              this.display_PackageAddPopup = false;
              //this.toastr.warning('Case Count Exceed Please Fill in Next Case');
              this.allowSave = false;
            }
          }
         
        }
        totalLength += element.Units.length;
         });
         const totalcounts = totalLength + sampleunits +destroyeddata;
        // Update Sanned Case Units
        this.updateSannedCaseUnits(data);
        if (this.allowSave == true) {
            if(scannedData !== 'Individual Units'){
          if (totalLength == unitCount) {
            // this.BatchDetailForm.patchValue({
            //   Batch_case: {
            //     Is_completed: true
            //   }
            // });
            this.batchUnits.Scanned_labels.Is_completed = true;
            this.display_PackageAddPopup = false;
          }
        }else if(unitCounts == totalcounts){
            // this.BatchDetailForm.patchValue({
            //   Batch_case: {
            //     Is_completed: true
            //   }
            // });
            this.batchUnits.Scanned_labels.Is_completed = true;
            this.display_PackageAddPopup = false;
        }
          this.batchUnits.Scanned_labels.Case_details = data;
        } 
        else {
          this.display_PackageAddPopup = false;
          this.batchUnits.Scanned_labels.Is_completed = true;
         // this.toastr.warning('Case Count Exceed Please Fill in Next Case');

        }

        // this.Pack.Barcode = '';
        // this.Pack.Is_used = false;
        // this.Pack.Status = '';
        if (this.allowSave == true) {
          this.AvailableRemover(a.Barcode);
          if (event == 'save') {

            // this.LowerCaseForm.patchValue(
            //   {
            //     id: this.Unitindex.toString()
            //   }
            // )
            let Case = data.filter(x => x.Case_no == caseNo);
            if (Case[0].Units.length >= QtyPerCase) {

              this.BatchUpdate();
              this.toastr.success('Case Filled Successfully');
            }
            this.LowerCaseForm.reset();
            // this.Pack.id = '';
          } else {
            this.LowerCaseForm.reset();
            let Case = data.filter(x => x.Case_no == caseNo);
            //if (QtyPerCase == unitCounts) {
              if(scannedData !== 'Individual Units'){
            if (Case[0].Units.length >= QtyPerCase) {
              // this.display_PackageAddPopup = false;

              this.BatchUpdate();
              this.toastr.success('Case Filled Successfully');
            }  else if(totalcounts == unitCounts){
              this.BatchUpdate();
              this.toastr.success('Case Filled Successfully');
            } 
          }
          }
        }

      }
    }
    
  }

  updateSannedCaseUnits(data) {
    data.forEach(element => {
      element.Used_quantity = element.Units.filter(x => x.Is_used).length;
      const totalQuantity = element.Units.length;
      element.Total_quantity = totalQuantity;
      const availableQuantity = element.Units.filter(x => !x.Is_used).length;
      element.Available_quantity = availableQuantity;
      element.Units.forEach((innerElement, index) => {
        innerElement.Index_no = index;
      });
      this.batchUnits.Scanned_labels.Case_details;
    });
  }
  // BatchUpdate() {
  //      
  //   this.spinnerService.show();
  //   const formValue = this.BatchDetailForm.value;
  //   this.dataService.update(formValue.id, this.configuration.batchRecord + '/UpdateBatchProcess/', formValue)
  //     .subscribe(
  //       (data: any) => (this.BatchOptionData2 = data),
  //       error => () => {
  //        
  //         this.spinnerService.hide();
  //       },
  //       () => {
  //         this.spinnerService.hide();
  //         this.BatchDetailForm.patchValue({
  //           Batch_case: this.BatchOptionData2.result.Batch_case,
  //         });
  //         // this.caselengthFinder(1);

  //         // this.BatchOptionData2.result.Batch_case.Tab_index;
  //       }
  //     );



  // }
  BatchUpdate() {
    // this.configuration.batchRecord
    this.dataService.update(this.batchUnits.id, this.configuration.batchRecord + '/UpdateBatchUnits/', this.batchUnits)
      .subscribe(
        (data: any) => (this.BatchOptionData2 = data),
        error => () => {

          this.spinnerService.hide();
        },
        () => {
          this.spinnerService.hide();
          // this.caselengthFinder(1);

          // this.BatchOptionData2.result.Batch_case.Tab_index;
        }
      );
  }



  onHide() {

    this.BatchPackageEmitter.emit(false);
  }

  moveFinalProduct() {

    if (this.isVerifyCheck()) {

      this.spinnerService.show();
      const formValue = this.BatchDetailForm.value;
      formValue.Is_moved = true;

      this.dataService.add<any>(this.configuration.batchRecord + '/AddLotDetails', formValue).subscribe(
        (data: any) => (this.responseBody = data),
        error => () => {
          this.spinnerService.hide();
          this.toastr.warning(this.toastMessage.Wrong);
        },
        () => {

          if (this.responseBody.message == 'Document updated successfully!!') {
            this.toastr.success('Cases Moved to Final Product Successfully');
            this.batchUnits.Is_moved = true;
            this.BatchUpdate();
          } else {
            this.toastr.warning(this.responseBody.message);
          }
          this.onHide();
          this.spinnerService.hide();

        }
      );
    }

    // this.dataService.add(this.configuration.LocalProduction + this.rootUrl + "/UpdateBatchProcess/", formValue)
    //   .subscribe(
    //     (data: any) => (this.BatchOptionData2 = data),
    //     error => () => {

    //       this.spinnerService.hide();
    //     },
    //     () => {

    //       this.spinnerService.hide();
    //       this.BatchDetailForm.patchValue({
    //         Batch_case: this.BatchOptionData2.result.Batch_case,
    //       })

    //     }

    //   );
  }

  // Avoid form Submit on enter
  keyDownFunction(event, type) {

    if (event.keyCode == 13) {
      if (this.barcodeVerifier()) {
        this.keyCode = 13;
        if (type == 'scannedLabel') {
          this.SavePackage('save&next');
        }
        if (type == 'sampleLabel') {
          this.scanSamplePackage();
        }
      }
    }
  }
  barcodeVerifier() {

    const LowerCaseForm = this.LowerCaseForm.getRawValue();
    if (LowerCaseForm.Barcode) {
      return true;
    } else {
      this.toastr.warning('Please Enter the Barcode');
      return false;
    }
  }

  // Destroy labels method

  destroyLabels() {

    this.confirmationService.confirm({
      message: 'Are you sure that you want to Destroy?',
      header: 'Destroy Labels Confirmation',
      icon: 'fa fa-sign-out',
      accept: () => {
        const Destroyed_label_units = this.batchUnits.Destroyed_labels.Units;
        const Available_label_units = this.batchUnits.Available_labels.Units;
        this.batchUnits.Destroyed_labels.Units = Destroyed_label_units.concat(Available_label_units);
        this.batchUnits.Destroyed_labels.Is_completed = true;
        this.batchUnits.Available_labels.Units = [];
        this.BatchUpdate();
        this.isCompleteCheck();
      },
      reject: () => {

      }
    });
  }

  // choose case type
  generateCaseType() {
    this.caseType = 'Individual_units';
    this.display_generateCasePopup = true;
    console.log("this.Tenant_id", this.Tenant_id);
    if(this.Tenant_id == "hydrationroom"){  
      this.generateCase();
    }
  }

  addSamplePackage(event) {
    this.display_SamplePackageAddPopup = true;
    this.addType = event;
    setTimeout(() => this.focusInputBarcodeFirst.nativeElement.focus(), 0);
  }

  AvailableRemover(Barcode) {

    const Available_labels = this.batchUnits.Available_labels;
    const index = Available_labels.Units.findIndex(x => x.Barcode === Barcode);
    this.batchUnits.Available_labels.Units.splice(index, 1);
  }

  scanSamplePackage() {
    if (this.barcodeVerifier()) {

      const a = this.LowerCaseForm.value;
      const lotNo = a.Barcode.slice(15, 21);
      const packageNo = a.Barcode.slice(31);
      //a.Index_no = packageNo;
      a.id = packageNo;
      const caseNo = this.currentCasNo;
      const UnitDetail = this.LowerCaseForm.value;
      const scannedData = this.batchUnits.Scanned_labels.Case_details;
      const sampleData = this.batchUnits.Sample_units;
      let scannedAlreadyexist = false, sampleAlreadyexist = false;

      if (scannedData.length > 0) {
        scannedData.forEach(element => {
          const filteredData = element.Units.filter(x => x.Barcode === a.Barcode);
          if (filteredData.length > 0) {
            scannedAlreadyexist = true;
          }
        });
      }
      if (sampleData.Units.length > 0) {
        const filteredData = sampleData.Units.filter(x => x.Barcode === a.Barcode);
        if (filteredData.length > 0) {
          sampleAlreadyexist = true;
        }
      }

      if (sampleAlreadyexist) {
        this.toastr.warning('Labels already Exists');
        this.LowerCaseForm.reset();
      } else if (scannedAlreadyexist) {
        this.toastr.warning('Labels already in Lot');
        this.LowerCaseForm.reset();
      } else if (lotNo !== this.batchUnits.Lot_number) {
        this.toastr.warning('Scanned Label is not associated with the Lot Number(' + lotNo + ')');
        this.LowerCaseForm.reset();
      } else {

        const newUnitNo = sampleData.Units.length + 1; // Sequential number based on array length
        sampleData.Units.push({ 
          "Unit_no": UnitDetail.id != '' ? UnitDetail.id : newUnitNo, 
          "Barcode": UnitDetail.Barcode 
        });
        // sampleData.Units.push({ "Unit_no": UnitDetail.id, "Barcode": UnitDetail.Barcode });
        sampleData.Units.sort((a, b) => parseFloat(a.Unit_no) - parseFloat(b.Unit_no));

        this.batchUnits.Sample_units = sampleData;
        this.AvailableRemover(a.Barcode);
        this.LowerCaseForm.reset();
      }
      this.isCompleteCheck();
    }

  }

cancelScannedPackage(){
this.display_PackageAddPopup = false;
}

cancelSamplePackage(){
  this.display_SamplePackageAddPopup = false;
}


  saveSamplePackage() {
    this.display_SamplePackageAddPopup = false;
    this.batchUnits.Sample_units.Is_completed = true;
    this.BatchUpdate();
    this.toastr.success('Sample Units Saved Successfully');
    this.isCompleteCheck();
    this.batchUnits.Sample_units.Is_verified = true;
  }
  SaveScannedPackage() {
    this.display_PackageAddPopup = false;
    //if(this.batchUnits.Available_labels.Units == 0)
      this.batchUnits.Scanned_labels.Is_completed = true;
    this.BatchUpdate();
    this.toastr.success('Scanned Units Saved Successfully');
    this.isCompleteCheck();
    this.batchUnits.Scanned_labels.Is_verified = true;
  }

  showVerifyDialog(type) {
    this.verifyType = type;    
    if(this.verifyType == 'scanned'){
      this.SaveScannedPackage();
    }else{
      this.saveSamplePackage()
    }
    this.display_UserVerifyPopup = true;
    
  }

  verifyUser(UserCodeForm) {
    const UserCodeFormValue = UserCodeForm.value;
    if (UserCodeFormValue.UserCode != '' && UserCodeFormValue.UserCode != undefined && UserCodeFormValue.UserCode != null) {
      this.dataService.add(this.configuration.manageUser + '/VerifyCode/' + UserCodeFormValue.UserCode + ',' + UserCodeFormValue.Type + ',' + UserCodeFormValue.ModelType, '').subscribe(
        (response: any) => {
          const value = response.message.result;
          this.display_UserVerifyPopup = false;
          if (value.id != undefined) {
            var obj = { "Name": value.Name, "By": value.id, "Date": new Date().toISOString() };
            if (this.verifyType === 'scanned') {
              this.batchUnits.Scanned_labels.Verified_info.push(obj);
              this.batchUnits.Scanned_labels.Is_verified = true;
            }
            if (this.verifyType === 'sample') {
              this.batchUnits.Sample_units.Verified_info.push(obj);
              this.batchUnits.Sample_units.Is_verified = true;
            }
            if (this.verifyType === 'destroy') {
              this.batchUnits.Destroyed_labels.Verified_info.push(obj);
              this.batchUnits.Destroyed_labels.Is_verified = true;
            }
            this.isCompleteCheck();
            this.toastr.success('User Code verified successfully');
            this.BatchUpdate();
          } else {
            this.toastr.warning(value);
          }
          this.UserCodeForm.patchValue({
            UserCode: ''
          });
        }, err => {
        });
    } else {
      this.toastr.warning('Usercode Required');
    }
  }
  isCompleteCheck() {
    const batchUnits = this.batchUnits;
    if (batchUnits.Scanned_labels.Is_completed && batchUnits.Available_labels.Units.length == 0) {
      this.batchUnits.Is_completed = true;
    }
  }
  isVerifyCheck() {
    const batchUnits = this.batchUnits;
    const errorArray = [];
    if (!batchUnits.Scanned_labels.Is_verified) {
      errorArray.push('Scanned Label');
    }
    if (!batchUnits.Destroyed_labels.Is_verified && batchUnits.Destroyed_labels.Units.length > 0) {
      errorArray.push('Destroyed Label');
    }
    if (!batchUnits.Sample_units.Is_verified && batchUnits.Sample_units.Units.length > 0) {
      errorArray.push('Sample Units');
    }

    // final check to confirm to proceed
    if (errorArray.length > 0) {
      let validationMessage = '';
      errorArray.forEach(element => {
        validationMessage += `<li> ${element} </li>`;
      });
      validationMessage = `<h3>Please Verify The Following</h2><ol> ${validationMessage} </ol>`;
      this.toastr.warning(validationMessage);
      return false;
    } else {
      return true;
    }

  }

  unitChange(event, index, caseNo) {
    if (event) {
      if (caseNo == 'Individual Units') {
        let individualcaseCount;
        if (this.batchUnits.Scanned_labels.Case_details.length == 1) {
          individualcaseCount = this.BatchDetailForm.value.Units_container;
        } else {
          // tslint:disable-next-line:max-line-length
          individualcaseCount = this.BatchDetailForm.value.Units_container % this.BatchDetailForm.value.Master_formulation_record.Quantity_per_case;
        }
        const caseCount = individualcaseCount - this.selectedCaseUnits.length;
        if (this.selectedCaseUnits.length < individualcaseCount) {
          this.selectedCaseUnits.push(this.batchUnits.Available_labels.Units[index]);
        } else {
          let indx = this.selectedLabels.findIndex(x => x == index);
          this.selectedLabels.splice(indx, 1);
          // this.chkDisable = true;
          this.toastr.warning('Case Limit is Reached');
        }

      } else {
        if (this.selectedCaseUnits.length < this.BatchDetailForm.value.Master_formulation_record.Quantity_per_case) {
          this.selectedCaseUnits.push(this.batchUnits.Available_labels.Units[index]);
        } else {
          let selectArray = [];
          selectArray = this.selectedLabels;
          let indx = selectArray.findIndex(x => x == index);
          selectArray.splice(indx, 1);
          this.selectedLabels = selectArray;
          // this.chkDisable = true;
          this.toastr.warning('Case Limit is Reached');
        }
      }
    } else {
      let removeIndex = this.selectedCaseUnits.findIndex(x => x.Unit_no == this.batchUnits.Available_labels.Units[index].Unit_no);
      this.selectedCaseUnits.splice(removeIndex, 1);
    }
  }

  fillCase(caseNo) {
    let unfilledCase = [];
    let caseDetails;
    unfilledCase = this.batchUnits.Scanned_labels.Case_details.filter(a => a.Is_filled == false);
    caseDetails = this.batchUnits.Scanned_labels.Case_details.filter(a => a.Case_no == caseNo)[0];
    if (unfilledCase.length > 0) {
      if (this.selectedCaseUnits.length == this.BatchDetailForm.value.Master_formulation_record.Quantity_per_case) {
        this.selectedCaseUnits.forEach((element, index) => {
          // tslint:disable-next-line:max-line-length
          unfilledCase[0].Units.push({ Index_no: index, id: element.Unit_no, Barcode: element.Barcode, Is_used: false, Status: this.BatchDetailForm.value.Status });
          let availableLabel = this.batchUnits.Available_labels.Units;
          this.batchUnits.Available_labels.Units.splice(availableLabel.findIndex(a => a.Unit_no == element.Unit_no), 1);
        });
        this.batchUnits.Scanned_labels.Case_details.forEach(element => {
          if (element.Case_no == unfilledCase[0].Case_no) {
            element.Units = unfilledCase[0].Units;
            element.Is_filled = true;
          }
        });
        this.BatchUpdate();
        this.selectedCaseUnits = [];
        this.caseLengthCheck();
        this.toastr.success('Case Filled successfully and move to next case');

      } else {
        if (caseNo == 'Individual Units') {
          let individualcaseCount;
          if (this.batchUnits.Scanned_labels.Case_details.length == 1) {
            individualcaseCount = this.BatchDetailForm.value.Units_container;
          } else {
            // tslint:disable-next-line: max-line-length
            individualcaseCount = this.BatchDetailForm.value.Units_container % this.BatchDetailForm.value.Master_formulation_record.Quantity_per_case;
          }
          const caseCount = individualcaseCount - this.selectedCaseUnits.length;
          if (this.selectedCaseUnits.length == individualcaseCount) {
            this.selectedCaseUnits.forEach((element, index) => {
              // tslint:disable-next-line:max-line-length
              caseDetails.Units.push({ Index_no: index, id: element.Unit_no, Barcode: element.Barcode, Is_used: false, Status: this.BatchDetailForm.value.Status });
              let availableLabel = this.batchUnits.Available_labels.Units;
              this.batchUnits.Available_labels.Units.splice(availableLabel.findIndex(a => a.Unit_no == element.Unit_no), 1);
            });
            this.batchUnits.Scanned_labels.Case_details.forEach(element => {
              if (element.Case_no == caseDetails.Case_no) {
                element.Units = caseDetails.Units;
                element.Is_filled = true;
              }
            });
            this.BatchUpdate();
            this.selectedCaseUnits = [];
            this.toastr.success('Case Filled successfully');
          } else {
            this.toastr.warning('Please add ' + caseCount + ' units to fill the case');
          }
        } else {
          const caseCount = this.BatchDetailForm.value.Master_formulation_record.Quantity_per_case - this.selectedCaseUnits.length;
          // tslint:disable-next-line:max-line-length
          this.toastr.warning('Please add ' + caseCount + ' units to fill the case');
        }
        this.caseLengthCheck();

      }

    } else {
      this.batchUnits.Scanned_labels.Is_completed = true;
      this.BatchUpdate();
      this.isCompleteCheck();
      this.showVerifyDialog('scanned');
    }
  }

  sampleUnitChange(event, index) {
    if (event) {
      this.batchUnits.Sample_units.Units.push(this.batchUnits.Available_labels.Units[index]);
      this.batchUnits.Available_labels.Units.splice(index, 1);
    } else {
      this.batchUnits.Sample_units.Units.splice(index, 1);
    }
    this.BatchUpdate();
  }

  LabelLotChange(event, caseNo) {
    this.selectedLabels = [];
    if (event == 'First') {
      this.selectedCaseUnits = [];
      let selectCount;
      if (caseNo != 'Individual Units') {
        selectCount = this.BatchDetailForm.value.Master_formulation_record.Quantity_per_case;
      } else {
        if (this.batchUnits.Scanned_labels.Case_details.length != 1) {
          selectCount = this.BatchDetailForm.value.Units_container % this.BatchDetailForm.value.Master_formulation_record.Quantity_per_case;
        } else {
          selectCount = this.BatchDetailForm.value.Units_container;
        }
      }
      for (let i = 0; i < selectCount; i++) {
        this.selectedLabels.push(i.toString());
        this.unitChange(true, i, caseNo);
      }
    } else {
      this.selectedCaseUnits = [];
      this.selectedLabels = [];
      let selectCount;
      if (caseNo != 'Individual Units') {
        selectCount = this.BatchDetailForm.value.Master_formulation_record.Quantity_per_case;
      } else {
        if (this.batchUnits.Scanned_labels.Case_details.length != 1) {
          selectCount = this.BatchDetailForm.value.Units_container % this.BatchDetailForm.value.Master_formulation_record.Quantity_per_case;
        } else {
          selectCount = this.BatchDetailForm.value.Units_container;
        }
      }
      let startCount = (this.batchUnits.Available_labels.Units.length) - selectCount;
      let endCount = this.batchUnits.Available_labels.Units.length;
      for (let i = startCount; i < endCount; i++) {
        this.selectedLabels.push(i.toString());
        this.unitChange(true, i, caseNo);
      }
    }
  }

  individualUnitsCount() {
    let count = this.batchUnits.Scanned_labels.Case_details;
    let caseName = this.batchUnits.Scanned_labels.Case_details[0].Case_no;
    if (count.length == 1 && caseName == 'Individual Units') {
      return this.BatchDetailForm.value.Units_container;
    } else {
      return this.BatchDetailForm.value.Units_container % this.BatchDetailForm.value.Master_formulation_record.Quantity_per_case;
    }
  }

  caseLengthCheck() {
    let unfilledCase = [];
    unfilledCase = this.batchUnits.Scanned_labels.Case_details.filter(a => a.Is_filled == false);
    this.unfilledCaseLength = unfilledCase.length;
  }

}

